import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import SearchPhraseTagInput from "../../../elements/searchPhraseTagInput.element"

const SemiAutomatedSearchPhraseView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isPrevButton,
    handlePrev,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const keywords = watch("keywords")
    const data = useSelector(ProductStateSelector)

    const onSubmit = () => {
        dispatch(ProductsActionCreator.selectedTrackpackPayload({ keywords: [keywords] }))
        handleNext && handleNext()
    }

    useEffect(() => {
        if (data?.selectedTrackpackPayload?.keywords) {
            setValue("keywords", data?.selectedTrackpackPayload.keywords.join(","))
        }
    }, [data, setValue])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[8px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>

            <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[4px] ml-[6px]">
                Give me some product-specific search phrases to help me zero in.
                <PrimaryText size="sm-medium" weight="light" className=" text-gray-700">
                    Enter up to 20 key words or phrases and hit Tab to enter.
                </PrimaryText>
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <>
                    <SearchPhraseTagInput
                        name="keywords"
                        className=" border-[#D0D5DD] rounded-[12px] placeholder:italic placeholder:text-gray-300 tag-input"
                        placeholder="E.g. 60-inch Smart TV or Portable Wireless Speaker"
                        reactHookControl={control}
                    />

                    <div className="flex justify-between  items-center mt-[48px]">
                        <div className="flex items-center">
                            <BackChevronIcon />
                            <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                                Back
                            </PrimaryText>
                        </div>

                        <ButtonElement
                            size="large"
                            textClass="text-[18px] leading-[28px]"
                            className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                        >
                            {keywords ? buttonText : "Skip"}
                        </ButtonElement>
                    </div>
                </>
            </form>
        </>
    )
}

export default SemiAutomatedSearchPhraseView
