import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ListingAnalyzerActionCreator } from "../../../../actions/listingAnalyzer.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { ReactComponent as Logo } from "../../../../assets/svgs/dashboard-logo.svg"
import useGetExtendedTrackpacks from "../../../../hooks/reactQueryHooks.hook"
import { AuthStateSelector } from "../../../../selectors/authState.selector"
import { ListingAnalyzerStateSelector } from "../../../../selectors/listingAnalyzerState.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import ListingReportModalStepThreeView from "./lisitingReportModalStepThreeView.component"
import ListingReportModalStepTwoView from "./lisitingReportModalStepTwo.component"
import ListingReportModalStepEighteenView from "./listingAnalyzerReportModalStepEighteenView.component"
import ListingReportModalStepEightView from "./listingAnalyzerReportModalStepEightView.component"
import ListingReportModalStepFifteenView from "./listingAnalyzerReportModalStepFifteenView.component"
import ListingReportModalStepFourteenView from "./listingAnalyzerReportModalStepFourteenView.component"
import ListingReportModalStepFourView from "./listingAnalyzerReportModalStepFourView.component"
import ListingReportModalStepNineView from "./listingAnalyzerReportModalStepNineView.component"
import ListingReportModalStepSevenView from "./listingAnalyzerReportModalStepSeven.component"
import ListingReportModalStepSeventeenView from "./listingAnalyzerReportModalStepSeventeenView.component"
import ListingReportModalStepSixteenView from "./listingAnalyzerReportModalStepSixteenView.component"
import ListingReportModalStepSixView from "./listingAnalyzerReportModalStepSixView.component"
import ListingReportModalStepTenView from "./listingAnalyzerReportModalStepTenView.component"
import ListingReportModalStepTwelveView from "./listingAnalyzerReportModalStepTewlveView.component"
import ListingReportModalStepThirteenView from "./listingAnalyzerReportModalStepThirteenView.component"
import ListingReportModalStepElevenView from "./listingAnalzerReportModalStepElevenView.component"
import ListingReportModalStepFiveView from "./listingReportModalStepFiveView.component"
import ListingReportModalStepOneView from "./listingReportModalStepOne.component"

const ListingAnalyzerReportFLowOutlet = () => {
    const { listingReportModal } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()

    const { structuredResponseData } = useSelector(ListingAnalyzerStateSelector)
    const { data, isLoading } = useGetExtendedTrackpacks({}, {}, false)
    const { getAccessLevel } = useSelector(AuthStateSelector)

    const [stepData, setStepData] = useState({})

    const flattenedQuestions = structuredResponseData?.flatMap((product: any) =>
        product.questions.map((question: any) => ({
            report_product_id: question?.report_product_id,
            report_question_id: question?.report_question_id,
            type: question?.question?.type,
            reason_prompt: question?.question?.reason_prompt,
            score_label_high: question?.question?.score_label_high,
            score_label_low: question?.question?.score_label_low,
            score_prompt: question?.question?.score_prompt,
            suggestion_prompt: question?.question?.suggestion_prompt,
            response: question?.response,
            isFinal: question?.is_final,
            reportID: question?.report_id,
        }))
    )

    const stepsConfig = [
        {
            id: "one",
            component: ListingReportModalStepOneView,
            asinHeaderText: "Select Trackpack",
            asinHeaderDescription: "To get started, pick a Trackpack you want to analyze.",
            next: "two",
        },
        {
            id: "two",
            component: ListingReportModalStepTwoView,
            asinHeaderText: "Choose Target ASIN",
            asinHeaderDescription: "This is the listing you want to analyze.",
            prev: "one",
            next: "three",
        },
        {
            id: "three",
            component: ListingReportModalStepThreeView,
            prev: "two",
            next: "four",
        },
        {
            id: "four",
            component: ListingReportModalStepFourView,
            prev: "three",
            next: "five",
        },
        {
            id: "five",
            component: ListingReportModalStepFiveView,
            prev: "four",
            next: "six",
        },
        {
            id: "six",
            component: ListingReportModalStepSixView,
            prev: "five",
            next: "seven",
        },
        {
            id: "seven",
            component: ListingReportModalStepSevenView,
            prev: "six",
            next: "eight",
        },
        {
            id: "eight",
            component: ListingReportModalStepEightView,
            prev: "seven",
            next: "nine",
        },
        {
            id: "nine",
            component: ListingReportModalStepNineView,
            prev: "eight",
            next: "ten",
        },
        {
            id: "ten",
            component: ListingReportModalStepTenView,
            prev: "nine",
            next: "eleven",
        },
        {
            id: "eleven",
            component: ListingReportModalStepElevenView,
            prev: "ten",
            next: "twelve",
        },
        {
            id: "twelve",
            component: ListingReportModalStepTwelveView,
            prev: "eleven",
            next: "thirteen",
        },
        {
            id: "thirteen",
            component: ListingReportModalStepThirteenView,
            prev: "twelve",
            next: "fourteen",
        },
        {
            id: "fourteen",
            component: ListingReportModalStepFourteenView,
            prev: "thirteen",
            next: "fifteen",
        },
        {
            id: "fifteen",
            component: ListingReportModalStepFifteenView,
            prev: "fourteen",
            next: "sixteen",
        },
        {
            id: "sixteen",
            component: ListingReportModalStepSixteenView,
            prev: "fifteen",
            next: "seventeen",
        },
        {
            id: "seventeen",
            component: ListingReportModalStepSeventeenView,
            prev: "sixteen",
            next: "eighteen",
        },
        {
            id: "eighteen",
            component: ListingReportModalStepEighteenView,
            prev: "seventeen",
        },
    ]

    const [filteredTrackpacks, setFilteredTrackpacks] = useState([])

    useEffect(() => {
        if (data?.trackpacks && getAccessLevel?.id) {
            const result = data.trackpacks.filter((trackpack: any) => trackpack.metadata.user === getAccessLevel.id)
            setFilteredTrackpacks(result)
        }
    }, [data?.trackpacks, getAccessLevel?.id])

    const stepsWithQuestions = stepsConfig?.map((step, index) => {
        if (index >= 3) {
            const stepQuestion = flattenedQuestions?.[index - 3]
            return {
                ...step,
                question: stepQuestion || null,
            }
        }
        return step
    })

    const mainImageFilenames =
        structuredResponseData?.map((item: { main_image_filename: string }) => item.main_image_filename) || []

    const currentStep = stepsConfig.find((step) => step.id === listingReportModal?.stepNumber)
    // @ts-ignore
    const currentStepQuestion = stepsWithQuestions.find((step) => step.id === currentStep?.id)?.question || {}

    const currentStepData = {
        // @ts-ignore
        ...stepData[currentStep?.id],
        // @ts-ignore
        score: stepData[currentStep?.id]?.score || currentStepQuestion?.response?.score || null,
        // @ts-ignore
        reason: stepData[currentStep?.id]?.reason || currentStepQuestion?.response?.reason || "",
        // @ts-ignore
        suggestion: stepData[currentStep?.id]?.suggestion || currentStepQuestion?.response?.suggestion || "",
    }

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeListingAnalyzerReportModalModal())
        dispatch(ListingAnalyzerActionCreator.removeReportData())
        if (getAccessLevel) {
            const options: { as_user?: string } = {}

            options.as_user = getAccessLevel.id + ""
            dispatch(ListingAnalyzerActionCreator.getReportsList({}, options))
        }
    }

    const handleNavigation = (stepId: string | undefined) => {
        if (!stepId) return

        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: stepId,
            })
        )
    }

    if (!listingReportModal?.open || !currentStep) return null

    const StepComponent = currentStep.component

    const updateStepData = (stepId: any, data: any) => {
        setStepData((prev) => ({
            ...prev,
            [stepId]: data,
        }))
    }

    return (
        <StepComponent
            icon={<Logo />}
            handleCloseDialog={handleCloseDialog}
            asinHeaderText={currentStep.asinHeaderText}
            asinHeaderDescription={currentStep.asinHeaderDescription}
            buttonText={currentStep.next ? "Next" : "Finish"}
            isPrevButton={!!currentStep.prev}
            handleNext={() => handleNavigation(currentStep.next)}
            handlePrev={() => handleNavigation(currentStep.prev)}
            totalSteps={stepsConfig.length}
            currentStep={stepsConfig.findIndex((step) => step.id === currentStep.id) + 1}
            previousStep={stepsConfig.findIndex((step) => step.id === currentStep.id) - 1}
            updateStepData={(data: any) => updateStepData(currentStep.id, data)}
            // @ts-ignore
            currentStepData={currentStepData}
            // @ts-ignore
            questions={stepsWithQuestions.find((step) => step.id === currentStep.id)?.question || []}
            extendedTrackpacks={filteredTrackpacks}
            isLoading={isLoading}
            mainImageFilenames={mainImageFilenames}
        />
    )
}

export default ListingAnalyzerReportFLowOutlet
