import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { ListingAnalyzerActionCreator } from "../../../actions/listingAnalyzer.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ListingAnalyzerIcon } from "../../../assets/svgs/listingAnalyzerIcon.svg"
import { ReactComponent as Plus } from "../../../assets/svgs/plus-icon.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import useGetExtendedTrackpacks from "../../../hooks/reactQueryHooks.hook"
import { AuthStateSelector } from "../../../selectors/authState.selector"
import { ListingAnalyzerStateSelector } from "../../../selectors/listingAnalyzerState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ListingAnalyzerSekeleton from "../../common/skeletonLoader/listingAnalyzerSekeleton.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import ListingReportModalStepThreeView from "../modals/lisitingAnalyzerModals/lisitingReportModalStepThreeView.component"
import ListingReportModalStepTwoView from "../modals/lisitingAnalyzerModals/lisitingReportModalStepTwo.component"
import ListingReportModalStepEighteenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepEighteenView.component"
import ListingReportModalStepEightView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepEightView.component"
import ListingReportModalStepFifteenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepFifteenView.component"
import ListingReportModalStepFourteenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepFourteenView.component"
import ListingReportModalStepFourView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepFourView.component"
import ListingReportModalStepNineView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepNineView.component"
import ListingReportModalStepSevenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepSeven.component"
import ListingReportModalStepSeventeenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepSeventeenView.component"
import ListingReportModalStepSixteenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepSixteenView.component"
import ListingReportModalStepSixView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepSixView.component"
import ListingReportModalStepTenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepTenView.component"
import ListingReportModalStepTwelveView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepTewlveView.component"
import ListingReportModalStepThirteenView from "../modals/lisitingAnalyzerModals/listingAnalyzerReportModalStepThirteenView.component"
import ListingReportModalStepElevenView from "../modals/lisitingAnalyzerModals/listingAnalzerReportModalStepElevenView.component"
import ListingReportModalStepFiveView from "../modals/lisitingAnalyzerModals/listingReportModalStepFiveView.component"
import ListingReportModalStepOneView from "../modals/lisitingAnalyzerModals/listingReportModalStepOne.component"

export const ListingAnalyzerLayoutComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { refetch } = useGetExtendedTrackpacks({}, {}, false)
    const location = useLocation()
    const { pathname } = location
    const reportId = location.state?.reportId
    const { reportPayload } = useSelector(ListingAnalyzerStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.reportListLoading(), [loading])
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { reportsListData, reportDataByID, structuredResponseData } = useSelector(ListingAnalyzerStateSelector)

    const stepsConfig = [
        {
            id: "one",
            component: ListingReportModalStepOneView,
            asinHeaderText: "Select Trackpack",
            asinHeaderDescription: "To get started, pick a Trackpack you want to analyze.",
            next: "two",
        },
        {
            id: "two",
            component: ListingReportModalStepTwoView,
            asinHeaderText: "Choose Target ASIN",
            asinHeaderDescription: "This is the listing you want to analyze.",
            prev: "one",
            next: "three",
        },
        {
            id: "three",
            component: ListingReportModalStepThreeView,
            prev: "two",
            next: "four",
        },
        {
            id: "four",
            component: ListingReportModalStepFourView,
            prev: "three",
            next: "five",
        },
        {
            id: "five",
            component: ListingReportModalStepFiveView,
            prev: "four",
            next: "six",
        },
        {
            id: "six",
            component: ListingReportModalStepSixView,
            prev: "five",
            next: "seven",
        },
        {
            id: "seven",
            component: ListingReportModalStepSevenView,
            prev: "six",
            next: "eight",
        },
        {
            id: "eight",
            component: ListingReportModalStepEightView,
            prev: "seven",
            next: "nine",
        },
        {
            id: "nine",
            component: ListingReportModalStepNineView,
            prev: "eight",
            next: "ten",
        },
        {
            id: "ten",
            component: ListingReportModalStepTenView,
            prev: "nine",
            next: "eleven",
        },
        {
            id: "eleven",
            component: ListingReportModalStepElevenView,
            prev: "ten",
            next: "twelve",
        },
        {
            id: "twelve",
            component: ListingReportModalStepTwelveView,
            prev: "eleven",
            next: "thirteen",
        },
        {
            id: "thirteen",
            component: ListingReportModalStepThirteenView,
            prev: "twelve",
            next: "fourteen",
        },
        {
            id: "fourteen",
            component: ListingReportModalStepFourteenView,
            prev: "thirteen",
            next: "fifteen",
        },
        {
            id: "fifteen",
            component: ListingReportModalStepFifteenView,
            prev: "fourteen",
            next: "sixteen",
        },
        {
            id: "sixteen",
            component: ListingReportModalStepSixteenView,
            prev: "fifteen",
            next: "seventeen",
        },
        {
            id: "seventeen",
            component: ListingReportModalStepSeventeenView,
            prev: "sixteen",
            next: "eighteen",
        },
        {
            id: "eighteen",
            component: ListingReportModalStepEighteenView,
            prev: "seventeen",
        },
    ]

    const flattenedQuestions = structuredResponseData?.flatMap((product: any) =>
        product.questions.map((question: any) => ({
            report_product_id: question?.report_product_id,
            report_question_id: question?.report_question_id,
            type: question?.question?.type,
            reason_prompt: question?.question?.reason_prompt,
            score_label_high: question?.question?.score_label_high,
            score_label_low: question?.question?.score_label_low,
            score_prompt: question?.question?.score_prompt,
            suggestion_prompt: question?.question?.suggestion_prompt,
            response: question?.response,
            isFinal: question?.is_final,
            reportID: question?.report_id,
        }))
    )

    const stepsWithQuestions = stepsConfig?.map((step, index) => {
        if (index >= 3) {
            const stepQuestion = flattenedQuestions?.[index - 3]
            return {
                ...step,
                question: stepQuestion || null,
            }
        }
        return step
    })
    const handleLisitingReportFlow = () => {
        refetch()
        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: "one",
            })
        )
    }
    useEffect(() => {
        if (getAccessLevel) {
            const options: { as_user?: string } = {}

            options.as_user = getAccessLevel.id + ""
            dispatch(ListingAnalyzerActionCreator.getReportsList({}, options))
        }
    }, [])

    useEffect(() => {
        const firstNullResponseStepId = stepsWithQuestions.find(
            // @ts-ignore
            (step) => step.question?.response === null
        )?.id
        if (firstNullResponseStepId && reportDataByID) {
            dispatch(
                RuntimeActionCreators.openListingAnalyzerReportModal({
                    open: true,
                    stepNumber: firstNullResponseStepId,
                })
            )
        } else if (reportPayload?.isEditMode && !firstNullResponseStepId) {
            dispatch(
                RuntimeActionCreators.openListingAnalyzerReportModal({
                    open: true,
                    stepNumber: "four",
                })
            )
        }
    }, [reportDataByID, reportId])

    useEffect(() => {
        if (pathname === ROUTES_CONFIG.reports) {
            dispatch(ListingAnalyzerActionCreator.setReportDataByID(undefined))
        }
    }, [reportDataByID])

    const redirectToListing = () => {
        navigate(ROUTES_CONFIG.reports)
    }

    return (
        <>
            <div
                style={{
                    height: "calc(100vh - 162px)",
                    border: "1px solid",
                    borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                }}
                className="p-[12px] rounded-[16px] bg-gray-50"
            >
                <div className="flex justify-between">
                    <div className="flex items-center gap-[8px] my-[2px] ml-[8px]">
                        <ListingAnalyzerIcon className="" color={"#0E7090"} />
                        <PrimaryText className="h-[16px] w-[16px] flex items-center justify-center text-gray-400">
                            /
                        </PrimaryText>
                        <PrimaryText
                            onClick={redirectToListing}
                            weight="medium"
                            size="md"
                            className="text-[#0E7090] my-[4px] cursor-pointer"
                        >
                            Reports
                        </PrimaryText>
                        {pathname === "/dashboard/reports/summary" && (
                            <>
                                <PrimaryText className="h-[16px] w-[16px] flex items-center justify-center text-gray-400">
                                    /
                                </PrimaryText>
                                <PrimaryText weight="light" size="small" className="text-gray-800 my-[4px] ml-[8px]">
                                    {reportDataByID?.trackpack?.name}
                                </PrimaryText>
                            </>
                        )}
                    </div>
                    <ButtonElement
                        onClick={handleLisitingReportFlow}
                        size="medium"
                        className="px-[12px] py-[10px] rounded-[10px] inline-flex h-[36px] items-center"
                        parentClass="justify-start items-center gap-2 inline-flex  "
                        textClass="text-[#088AB2] flex item-center gap-[8px]"
                    >
                        <Plus />
                        Report
                    </ButtonElement>
                </div>
                {isLoading ? (
                    <div className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] ">
                        <div className="w-16 h-16 rounded-full animate-spin border  border-[#0E7090] border-t-transparent"></div>
                    </div>
                ) : !reportsListData?.reports.length ? (
                    <ListingAnalyzerSekeleton />
                ) : (
                    <Outlet />
                )}
            </div>
        </>
    )
}
