import { ImageUnavailable } from "../../../assets/svgs/imageUnavailable.svg"
import { NewExternalLinkIcon } from "../../../assets/svgs/newLinkIcon.svg"
import { ReactComponent as ReportSummaryIcon } from "../../../assets/svgs/report-summary.svg"
import { img_url } from "../../../config/dashboard.config"
import { handleAmazonProductRedirect } from "../../../helpers/util.helper"
import { PrimaryText } from "../../elements/primaryText.element"

const ReportSummary = ({
    reportSummary,
    sortedProducts,
    marketPlace,
}: {
    reportSummary: any
    sortedProducts: any
    marketPlace: string
}) => {
    const handleProductRedirect = (e: React.MouseEvent<HTMLDivElement>, asin: string) => {
        e.stopPropagation()
        handleAmazonProductRedirect(marketPlace, asin)
    }
    return (
        <>
            <div className="mt-[20px] overflow-y-auto -mr-[30px] w-full">
                <div className="mt-[28px] bg-[#ECFDFF] border border-[#A5F0FC] rounded-[12px] p-6">
                    <div
                        className="p-[8px] inline-flex items-center justify-center rounded-[12px] bg-[#67E3F9]"
                        style={{
                            boxShadow: "0px 1px 2px 0px #1018280D",
                        }}
                    >
                        <ReportSummaryIcon />
                    </div>
                    <PrimaryText weight="medium" className="text-gray-700 text-[24px] my-[26px] ml-[6px]">
                        Report Summary
                    </PrimaryText>

                    {/* Overview */}
                    <div className="border border-[#A5F0FC] p-[24px] rounded-[24px] bg-white">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            {reportSummary?.content.overview.heading}
                        </PrimaryText>
                        <PrimaryText weight="light" size="small" className="text-gray-600">
                            {reportSummary.content.overview.text}
                        </PrimaryText>
                    </div>
                    {/* Target Analysis */}
                    <div className="border border-[#A5F0FC] p-[24px] my-[24px] rounded-[24px] bg-white">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            {reportSummary.content.target_analysis.heading}
                        </PrimaryText>
                        <div className="grid grid-cols-2 gap-3">
                            {(() => {
                                const targetAnalysis = reportSummary?.content?.target_analysis
                                const matchedProduct = sortedProducts?.find(
                                    (product: any) => product?.asin === targetAnalysis?.content.asin
                                )

                                return (
                                    <div className="bg-[#F5FEFF] p-[16px] py-[20px] border border-[#A5F0FC] rounded-[12px] flex items-start gap-[14px]">
                                        <div>
                                            <div className="w-[144px] h-[144px] rounded-[10px] border border-[#A5F0FC] bg-white">
                                                {matchedProduct ? (
                                                    <img
                                                        src={`${img_url}${matchedProduct.main_image_filename}`}
                                                        alt={`Target Product`}
                                                        className="w-full h-full object-contain rounded-[10px]"
                                                    />
                                                ) : (
                                                    <ImageUnavailable width={"144"} height={"144"} />
                                                )}
                                            </div>
                                            <div
                                                className={`bg-transparent mt-[22px] relative cursor-pointer border rounded-[3px] px-[4px] py-[3px] inline-flex gap-[3px] justify-center `}
                                                style={{ borderColor: `#06AED4` }}
                                            >
                                                <p
                                                    onClick={(e) =>
                                                        handleProductRedirect(e, targetAnalysis.content.asin)
                                                    }
                                                    className={`text-[#06AED4] text-[11px] tracking-[0.7px] font-[500] leading-[10px] uppercase`}
                                                >
                                                    {targetAnalysis.content.asin}
                                                </p>
                                                <div
                                                    onClick={(e) =>
                                                        handleProductRedirect(e, targetAnalysis.content.asin)
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <NewExternalLinkIcon color={"#06AED4"} className="" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Display strengths and weaknesses */}
                                        <div>
                                            <PrimaryText
                                                size="small"
                                                weight="medium"
                                                className="text-[#06AED4] mb-[12px]"
                                            >
                                                Target Product Analysis
                                            </PrimaryText>

                                            {/* Strengths */}
                                            <PrimaryText size="xs" weight="medium" className="text-gray-600 mb-[2px]">
                                                Strengths
                                            </PrimaryText>
                                            <ul className="list-decimal mb-[6px] pl-[22px]">
                                                {targetAnalysis.content.strengths.map((strength: any, idx: any) => (
                                                    <li
                                                        key={idx}
                                                        className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]"
                                                    >
                                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                            {strength}
                                                        </PrimaryText>
                                                    </li>
                                                ))}
                                            </ul>

                                            {/* Weaknesses */}
                                            <PrimaryText size="xs" weight="medium" className="text-gray-600 mb-[2px]">
                                                Weaknesses
                                            </PrimaryText>
                                            <ul className="list-decimal pl-[22px]">
                                                {targetAnalysis.content.weaknesses.map((weakness: any, idx: any) => (
                                                    <li
                                                        key={idx}
                                                        className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]"
                                                    >
                                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                            {weakness}
                                                        </PrimaryText>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })()}
                        </div>
                    </div>

                    {/* Key Takeaways */}
                    <div className="border border-[#A5F0FC] p-[24px] rounded-[24px] bg-white my-[24px]">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            {reportSummary.content.key_takeaways.heading}
                        </PrimaryText>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                            {reportSummary.content.key_takeaways.content.map((item: any, index: any) => (
                                <div
                                    key={index}
                                    className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px]"
                                >
                                    <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[6px]">
                                        {item.heading}
                                    </PrimaryText>
                                    <ul className="list-disc marker:text-gray-600 pl-[22px]">
                                        <li className="mb-[6px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                <b className="font-medium underline">Feedback:</b>&nbsp; {item.feedback}
                                            </PrimaryText>
                                        </li>
                                        <li className="mb-[6px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                <b className="font-medium underline">Recommendations:</b>&nbsp;{" "}
                                                {item.recommendations}
                                            </PrimaryText>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Competitor Analysis */}
                    <div className="border border-[#A5F0FC] p-[24px] rounded-[24px] bg-white">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            {reportSummary.content.competitor_analysis.heading}
                        </PrimaryText>
                        <div className="grid grid-cols-2 gap-3">
                            {reportSummary.content.competitor_analysis.content.map(
                                (competitor: { asin: string; strengths: any[]; weaknesses: any[] }, index: any) => {
                                    const matchedProduct = sortedProducts.find(
                                        (product: any) => product.asin === competitor.asin
                                    )
                                    return (
                                        <div
                                            key={index}
                                            className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px] flex items-start gap-[14px]"
                                        >
                                            <div>
                                                <div className="w-[144px] h-[144px] rounded-[10px] border border-[#A5F0FC] bg-white">
                                                    {matchedProduct ? (
                                                        <img
                                                            src={`${img_url}${matchedProduct.main_image_filename}`}
                                                            alt={`Competitor ${index + 1}`}
                                                            className="w-full h-full object-contain rounded-[10px]"
                                                        />
                                                    ) : (
                                                        <ImageUnavailable width={"144"} height={"144"} />
                                                    )}
                                                </div>
                                                <div
                                                    className={`bg-transparent mt-[22px] relative cursor-pointer border rounded-[3px] px-[4px] py-[3px] inline-flex gap-[3px] justify-center `}
                                                    style={{ borderColor: `#06AED4` }}
                                                >
                                                    <p
                                                        onClick={(e) => handleProductRedirect(e, competitor?.asin)}
                                                        className={`text-[#06AED4] text-[11px] tracking-[0.7px] font-[500] leading-[10px] uppercase`}
                                                    >
                                                        {competitor.asin}
                                                    </p>
                                                    <div
                                                        onClick={(e) => handleProductRedirect(e, competitor?.asin)}
                                                        className="cursor-pointer"
                                                    >
                                                        <NewExternalLinkIcon color={"#06AED4"} className="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <PrimaryText
                                                    size="small"
                                                    weight="medium"
                                                    className="text-[#06AED4] mb-[12px]"
                                                >
                                                    Competitor # {index + 1}
                                                </PrimaryText>
                                                <PrimaryText
                                                    size="xs"
                                                    weight="medium"
                                                    className="text-gray-600 mb-[2px]"
                                                >
                                                    Strengths
                                                </PrimaryText>
                                                <ul className="list-decimal mb-[6px] pl-[22px]">
                                                    {competitor.strengths.map((strength, idx) => (
                                                        <li
                                                            key={idx}
                                                            className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]"
                                                        >
                                                            <PrimaryText
                                                                weight="light"
                                                                size="xs"
                                                                className="text-gray-600"
                                                            >
                                                                {strength}
                                                            </PrimaryText>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <PrimaryText
                                                    size="xs"
                                                    weight="medium"
                                                    className="text-gray-600 mb-[2px]"
                                                >
                                                    Weaknesses
                                                </PrimaryText>
                                                <ul className="list-decimal pl-[22px]">
                                                    {competitor.weaknesses.map((weakness, idx) => (
                                                        <li
                                                            key={idx}
                                                            className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]"
                                                        >
                                                            <PrimaryText
                                                                weight="light"
                                                                size="xs"
                                                                className="text-gray-600"
                                                            >
                                                                {weakness}
                                                            </PrimaryText>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportSummary
