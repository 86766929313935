import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { ListingAnalyzerActionCreator } from "../../../actions/listingAnalyzer.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as Badge } from "../../../assets/svgs/badge.svg"
import { DownloadIcon } from "../../../assets/svgs/downloadIcon.svg"
import { ReactComponent as EditIcons } from "../../../assets/svgs/editIcons.svg"
import CarouselIcon from "../../../assets/svgs/filters/CarouselFilterIcon.svg"
import { ReactComponent as SummaryIcon } from "../../../assets/svgs/summaryIcon.svg"
import { img_url } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { ListingAnalyzerStateSelector } from "../../../selectors/listingAnalyzerState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { PrimaryText } from "../../elements/primaryText.element"
import ReportSummary from "./reportSummary.component"

const ReportTable = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const reportId = location.state?.reportId

    const { reportDataByID, reportSummary } = useSelector(ListingAnalyzerStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading = useMemo(() => RuntimeHelper.reportDataByIDLoading(), [loading])
    const isSummaryLoading = useMemo(() => RuntimeHelper.reportSummaryLoading(), [loading])

    useEffect(() => {
        dispatch(
            ListingAnalyzerActionCreator.getReportDataByID(reportId, {}, {}, () => {
                dispatch(ListingAnalyzerActionCreator.getReportSummary(reportId))
            })
        )
    }, [reportId])

    const sortedProducts = reportDataByID?.products?.sort(
        (a: { order: number }, b: { order: number }) => a.order - b.order
    )

    const questionTypeLabels = {
        CLICKABILITY: "Clickability",
        DIFFERENTIATION: "Differentiation",
        FEATURE_CLARITY: "Clarity of Product Features",
        PRODUCT_QUALITY: "Clarity of Product Quality",
        PRODUCTION_VALUE: "Trustability",
    }

    const products = reportDataByID?.products?.map((product: any) => ({
        report_product_id: product.report_product_id,
        CLICKABILITY: null,
        DIFFERENTIATION: null,
        FEATURE_CLARITY: null,
        PRODUCT_QUALITY: null,
        PRODUCTION_VALUE: null,
    }))

    reportDataByID?.report_questions.forEach((question: any) => {
        const product = products.find((p: any) => p?.report_product_id === question?.report_product_id)
        if (product && question.question.type) {
            product[question.question.type] = question.response?.score || null
        }
    })

    const handleEditReportRedirect = () => {
        dispatch(ListingAnalyzerActionCreator.setReportData({ isEditMode: true }))
        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: "four",
            })
        )
    }

    return (
        <>
            <div className="flex items-center justify-between mt-[42px] ml-[4px]">
                <div className="flex items-center gap-[12px] mt-[12px]">
                    <div className="bg-[#67E3F9] p-[6px] inline-flex items-center justify-center rounded-[8px]">
                        <CarouselIcon width={"28"} height={"28"} strokeColor={"#088AB2"} />
                    </div>
                    <PrimaryText weight="medium" size="lg" className="text-[#088AB2]">
                        Main Image Analysis Report
                    </PrimaryText>
                </div>
                <div className="flex items-center gap-[8px] mt-[16px]">
                    <div
                        onClick={handleEditReportRedirect}
                        className="bg-white border cursor-pointer border-gray-200 p-[8px] rounded-[10px] inline-flex items-center justify-center"
                    >
                        <EditIcons />
                    </div>

                    <div className="bg-white border cursor-pointer border-gray-200 p-[8px] rounded-[10px] inline-flex items-center justify-center">
                        <DownloadIcon />
                    </div>
                    <div className="bg-[#ECFDFF] cursor-pointer border border-[#67E3F9] rounded-[10px] inline-flex items-center gap-[4px] justify-center px-[12px] py-[8px]">
                        <SummaryIcon />
                        <PrimaryText weight="medium" size="small" className="text-[#06AED4]">
                            Generate Brief
                        </PrimaryText>
                    </div>
                </div>
            </div>
            <div
                className="relative"
                style={{
                    height: "calc(100vh - 327px)",
                }}
            >
                {isLoading ? (
                    <div className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] ">
                        <div className="w-16 h-16 rounded-full animate-spin border  border-[#0E7090] border-t-transparent"></div>
                    </div>
                ) : (
                    <div className=" mt-[20px] overflow-y-auto -mr-[30px] ml-[4px]" style={{}}>
                        <div
                            className=""
                            style={{
                                height: "calc(100vh - 327px)",
                                width: "calc(100% - 26px)",
                            }}
                        >
                            {/* Table Section */}
                            <div className="border border-gray-200 rounded-[10px]">
                                {/* Images Row */}
                                <div className="grid grid-cols-4 items-end justify-end bg-gray-100 py-[16px] pl-[16px] pr-[20px] rounded-tl-[11px] rounded-tr-[11px]">
                                    <PrimaryText
                                        size="small"
                                        weight="medium"
                                        className="col-span-1 flex items-end text-gray-500"
                                    >
                                        ASINs
                                    </PrimaryText>
                                    <div className="col-span-3 flex justify-end">
                                        {sortedProducts?.map((product: any) => (
                                            <div key={product?.report_product_id} className="relative">
                                                <img
                                                    src={`${img_url}${product?.main_image_filename}`}
                                                    alt={`Product ${product?.report_product_id}`}
                                                    className="w-[144px] h-[144px] mx-[38px] border border-gray-200 mt-[2px] bg-white object-contain rounded-[10px]"
                                                />
                                                {product.is_target && (
                                                    <div className="absolute right-[40px] top-0 drop-shadow-custom">
                                                        <Badge />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Metrics Table */}
                                <div className="border-t">
                                    {Object.entries(questionTypeLabels)?.map(([key, metricLabel]) => {
                                        const values = products?.map((product: any) => product[key] ?? "-")
                                        return (
                                            <div
                                                key={key}
                                                className={`grid grid-cols-4 gap-4 items-center bg-white py-[12px] pl-[32px] pr-[20px] border-b border-b-[#EEE]`}
                                            >
                                                <PrimaryText
                                                    weight="light"
                                                    size="small"
                                                    className="col-span-1 text-gray-600"
                                                >
                                                    {metricLabel}
                                                </PrimaryText>
                                                <div className="col-span-3 flex items-center justify-end">
                                                    {values?.map((value: any, idx: any) => (
                                                        <PrimaryText
                                                            weight="light"
                                                            size="xs"
                                                            key={idx}
                                                            className="flex items-center justify-center text-gray-600 max-w-[220px] w-full"
                                                        >
                                                            {value}
                                                        </PrimaryText>
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                {/* Totals Row */}
                                <div className="grid grid-cols-4 gap-4 items-center py-[12px] pl-[16px] pr-[20px] bg-gray-100 rounded-bl-[11px] rounded-br-[11px]">
                                    <PrimaryText weight="medium" size="small" className="col-span-1 text-gray-600">
                                        Totals
                                    </PrimaryText>
                                    <div className="col-span-3 flex justify-end">
                                        {products
                                            ?.map((product: any) => {
                                                const totalScore = Object.keys(questionTypeLabels).reduce(
                                                    (sum, key) => {
                                                        const score = product[key]
                                                        return sum + (score || 0)
                                                    },
                                                    0
                                                )
                                                return totalScore
                                            })
                                            // .sort((a: any, b: any) => a - b) // Sort totals in ascending order
                                            .map((total: any, idx: any) => (
                                                <PrimaryText
                                                    key={idx}
                                                    size="xs"
                                                    weight="medium"
                                                    className="flex items-center justify-center max-w-[220px] w-full text-gray-600"
                                                >
                                                    {total}
                                                </PrimaryText>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            {isSummaryLoading ? (
                                <div className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] ">
                                    <div className="w-16 h-16 rounded-full animate-spin border  border-[#0E7090] border-t-transparent"></div>
                                </div>
                            ) : (
                                reportSummary && (
                                    <ReportSummary
                                        reportSummary={reportSummary}
                                        sortedProducts={sortedProducts}
                                        marketPlace={reportDataByID?.trackpack?.amazon_tld}
                                    />
                                )
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ReportTable
